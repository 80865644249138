import { createFileRoute } from '@tanstack/react-router'

import PartnerUserPage from '../../../../../components/pages/backoffice/partners/partners-user-page'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/partner/$organization/users/$userId')({
  component: () => (
    <PermissionChecker requiredPermissions={['users.read']}>
      <PartnerUserPage />
    </PermissionChecker>
  ),
})
